import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="indicator-form"
export default class extends Controller {
  connect() {
    let indicatorTypeSelected = document.getElementById("indicator_type");
    let rangeSelection = document.getElementById("rangeSelection");
    let monthList = document.getElementById("monthList");
    let referenceYearSelectionField = document.getElementById(
      "reference_year_selection",
    );
    let referenceYearSelection = document.getElementById("reference_year");
    indicatorTypeSelected.addEventListener("change", () => {
      switch (indicatorTypeSelected.value) {
        case "Projeção":
          rangeSelection.classList.add("d-none");
          monthList.classList.add("d-none");
          referenceYearSelectionField.classList.add("d-none");
          referenceYearSelection.required = false;
          break;
        case "Execução":
          rangeSelection.classList.remove("d-none");
          monthList.classList.remove("d-none");
          referenceYearSelectionField.classList.remove("d-none");
          referenceYearSelection.required = true;
          break;
        default:
          rangeSelection.classList.add("d-none");
          monthList.classList.add("d-none");
          referenceYearSelectionField.classList.add("d-none");
          referenceYearSelection.required = false;
      }
    });
  }
}
