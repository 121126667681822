import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="profile-picture-preview"
export default class extends Controller {
  connect() {
    console.log("teste");
    let uploadButton = document.getElementById("user_profile_picture");
    let chosenImg = document.getElementById("c_user_profile_picture");
    uploadButton.onchange = () => {
      let reader = new FileReader();
      reader.readAsDataURL(uploadButton.files[0]);
      reader.onload = () => {
        chosenImg.setAttribute("src", reader.result);
      };
    };
  }
}
