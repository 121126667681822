import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="reports"
export default class extends Controller {
  connect() {}

  updateFormList() {
    let planIdElement = document.getElementById("plan_id");
    let yearField = document.getElementById("year");
    let monthField = document.getElementById("month");

    fetch("/plans.json")
      .then((response) => response.json())
      .then((data) => {
        const itemObject = data.filter(
          (selected) => selected.id === planIdElement.value,
        );
        console.log(itemObject);
      });
  }
}
