import { Controller } from "@hotwired/stimulus"
import { Chart, registerables } from "chart.js"

Chart.register(...registerables)

Chart.defaults.backgroundColor = getCssVariableValue("--color-primary")
Chart.defaults.borderColor = getCssVariableValue("--color-border")
Chart.defaults.color = getCssVariableValue("--color-text")

Chart.defaults.font.family = getCssVariableValue("--font-system-ui")
Chart.defaults.font.size = 14

// Plugin para adicionar background ao canvas
const customCanvasBackgroundColor = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#ffffff'; // Cor padrão branco
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
};

export default class extends Controller {
  static values = { type: { type: String, default: "line" }, data: Object, options: Object }

  connect() {
    this.chart = new Chart(this.element, this.#settings)
  }

  get #settings() {
    return { type: this.typeValue, data: this.dataValue, options: this.optionsValue }
  }
}

// Helpers

function getCssVariableValue(variableName) {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim()
}