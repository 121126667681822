import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

// Connects to data-controller="slim"
export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element,
      settings: {
        // Below are a list of optional fields
        // their values are the defaults
        disabled: false,
        alwaysOpen: false,
        showSearch: true,
        searchPlaceholder: 'Buscar',
        searchText: 'Sem resultado',
        searchingText: 'Buscando...',
        searchHighlight: false,
        closeOnSelect: true,
        contentLocation: document.body,
        contentPosition: 'absolute',
        openPosition: 'auto', // options: auto, up, down
        placeholderText: 'Select Value',
        allowDeselect: false,
        hideSelected: false,
        showOptionTooltips: false,
        minSelected: 0,
        maxSelected: 1000,
        timeoutDelay: 200,
        maxValuesShown: 20,
        maxValuesMessage: '{number} selected',
      },
    })
  }
}
