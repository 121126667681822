import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="custom-accordion-table"
export default class extends Controller {
  connect() {
    let scrollArea = document.querySelector(".indicators-body");
    let division = document.querySelectorAll(".div-scroll-spy");
    let navLinks = document.querySelectorAll(".nav-bar-area nav a");
    scrollArea.onscroll = () => {
      division.forEach((div) => {
        let top = scrollArea.scrollTop;
        let offset = div.offsetTop - 200;
        let height = div.offsetHeight;
        let id = div.getAttribute("id");

        if (top >= offset && top < offset + height) {
          navLinks.forEach((links) => {
            links.classList.remove("active");
            document
              .querySelector("nav a[href*=" + id + "]")
              .classList.add("active");
          });
        }
      });
    };
  }
}
